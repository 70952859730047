*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color:#000000;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
    text-align: center;
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue", Helvetica, Verdana, Arial, Sans, sans-serif;
    font-size: 18px;
    font-weight: 300;
}

