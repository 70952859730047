.section {
    clear: both;
    padding: 0px;
    margin: 0 0 0.8em 0;
}

.col {
    display: block;
    float: left;
    margin: 0 0 0 1.6%;
}

.col:first-child {
    margin-left: 0;
}

.group:before,
.group:after {
    content: "";
    display: table;
}

.group:after {
    clear: both;
}

.group {
    zoom: 1;
    /* For IE 6/7 */
}

.span_6_of_6 {
    width: 100%;
}

.span_5_of_6 {
    width: 83.06%;
}

.span_4_of_6 {
    width: 66.13%;
}

.span_3_of_6 {
    width: 49.2%;
}

.span_2_of_6 {
    width: 32.26%;
}

.span_1_of_6 {
    width: 15.33%;
}

#content {
    background-color: #fff;
    padding: 5px 0 1.8em 0;
}

#content>div {
    text-align: left;
    margin: 0em auto;
    padding: 0 10px;
    width: 760px;
}

#content h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

#content h3 {
    font-size: 1.2rem;
    margin: 0 0 0.2em 0;
}

p {
    margin: 18px 0;
}

/* -- small screen devices ---------- */

/* @media (max-device-width: 414px) { */

@media only screen and (max-width: 760px) {
    .col {
        margin: 0 0 0.8em 0;
    }

    .span_1_of_6,
    .span_2_of_6,
    .span_3_of_6,
    .span_4_of_6,
    .span_5_of_6,
    .span_6_of_6 {
        width: 100%;
    }

    #nav .span_1_of_6 {
        width: 50%;
    }

    .col .button,
    .col .primary_button,
    .col .small_button {
        font-size: 1rem;

    }

    .help_block {
        font-size: 0.9em;
    }

    input[type="checkbox"] {
        zoom: 1;
    }

    #header {
        width: auto;
        height: auto;
        padding: 5px 10px;
    }

    #header_bg {
        border-bottom: 0px solid #f1f1f1;
    }

    #header .h1 {
        float: none;
        width: auto;
    }

    #header .h1 img {
        height: 40px;
    }

    #header h2 {
        display: none;
    }

    #ad {
        padding: 15px 0 5px 0;
        width: auto;
    }

    #top_banner,
    #bottom_banner {
        width: auto;
        font-size: 0.75em;
    }

    #unsupported {
        width: auto;
    }

    #content {
        padding-bottom: 1rem;
    }

    #content>div {
        width: auto;
    }

    #content h3 {
        margin-top: 0;
    }

    #content textarea {
        height: 150px;
    }

    #note_link {
        font-size: 0.9em;
    }

    #footer {
        font-size: 0.9rem;
    }

    #footer>div {
        width: auto;
        margin-top: 0;
    }

    #footer .col {
        margin-bottom: 0.8em;
        line-height: 1.7rem;
    }

    #footer #nav {
        margin-bottom: 0;
    }

    .articles-row,
    .article-row {
        grid-template-columns: 1fr;
    }

    .cookie-info .cookie-logo img {
        width: 100%;
    }

    .cookie-info .cookie-buttons {
        flex-direction: column;
        row-gap: 14px;
    }

    .cookie-info .cookie-buttons .cookie-cancel,
    .cookie-info .cookie-buttons .cookie-agree {
        width: 100%;
    }
}