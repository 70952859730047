#header_bg {
    background-color: #960303;
    color: #fff;
    border-bottom: 14px solid #f1f1f1;
}

#header {
    text-align: left;
    margin: 0 auto;
    padding: 8px 0;
    width: 740px;
    height: 74px;
}

#header a {
    color: #fff;
    text-decoration: none;
}
#header h1 {
    font-weight: 300;
    font-size: 0.8em;
    /* For IE8 */
    font-size: 0.78rem;
    color: #ddd;
    margin: 0;
    float: left;
    width: 220px;
    padding-top: 14px;

}

#header .h1 {
    font-size: 1rem;
    margin: 0;
    float: left;
    width: 230px;
    position: relative;
}
#header .h1::after {
    content: '';
    position: absolute;
    width: 212px;
    height: 4px;
    background: #ddd;
    bottom: 0px;
    left: 0;
    visibility: hidden;
}

#header .h1 a span {
    left: -1000px;
    position: absolute;
}

#header .h1 img {
    border: 0;
    box-sizing: border-box;
    height: 55px;
}

#header .h1:hover::after {

    visibility: visible;
}

#header h2 {
    font-weight: 300;
    font-size: 0.8em;
    /* For IE8 */
    font-size: 0.78rem;
    color: #ddd;
    margin: 0;
    float: left;
    width: 220px;
    padding-top: 14px;
}
