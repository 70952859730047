
div.note_password_wrapper{
    margin: 1rem 0 !important;
}

.error {
    display: block;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 0.4em;
    margin-top: -1px;
    padding: 0.33333rem 0.5rem 0.5rem;
    padding-top: 0.33333rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    background: #f04124;
    color: #FFFFFF;
    width: 100%;
}