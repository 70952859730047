#footer {
    background-color: #f1f1f1;
    color: #888;
    font-size: 0.8rem;
    padding: 1em 0 0 0;
}

#footer a {
    color: #888;
}

#footer>div {
    margin: 0 auto;
    width: 760px;
}

#footer .col {
    line-height: 1.5rem;
}

#footnote {
    font-size: 0.7rem;
    padding: 1em;
}

#footer select {
    color: #888;
    margin-bottom: 0.6em;
}