.hidden {
    display: none !important;
}

#content input[type="text"], #content input[type="email"], #content input[type="number"], #content input[type="password"], #content select {
    width: 100%;
    font-size: 1rem;
}

#content textarea {
    background-color: #ffface;
    width: 100%;
    max-width: 100%;
    font-size: 0.9rem;
    font-family: "Lucida Console", Monaco, monospace;
    height: 250px;
    margin-bottom: 1em;
    border: none;
    box-shadow: none;
    background: #ffface url('../../assets/privnote-page-flip-30.png') no-repeat bottom right;
}

/*Info content*/
.info-content {
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.full-info-row {
    background: #f1f1f1;
    text-align: center;
    padding: 20px;
}

.full-info-row .full-info-title {
    font-size: 24px;
    color: #888;
    padding: 18px 0;
}

.full-info-row .full-info-text {
    font-size: 16px;
    color: #888;
}

#note_link_input {
    background-color: #ffa;
    font-weight: bold;
}

#note_link_a {
    padding: 2.5rem 0;
    display: block;
    text-align: center;
    background-color: #ffa;
    font-weight: bold;
    font-size: 0.9rem;
}

#note_password_span {
    display: block;
    padding: 1rem 0;
    text-align: center;
    background-color: #f0f0f0;
    font-weight: bold;
    font-size: 1rem;
}

#note_contents_div {
    margin: 0 0 1em 0;
    padding: 2.5rem 1rem;
    font-size: 0.8rem;
    font-family: "Lucida Console", Monaco, monospace;
    background: #ffface url('../../assets/privnote-page-flip-30.png') no-repeat bottom right;
}

#advanced_options_tip {
    font-weight: 300;
    font-style: italic;
    font-size: 0.8em;
    /* for IE8 */
    font-size: 0.8rem;
    text-align: center;
    margin: 0 0 1em 0;
}

select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-color: #FAFAFA;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='20px' height='5px'><polygon fill='%23555' points='10,0 5,5 0,0'/></svg>");
    background-position: 100% center;
    background-repeat: no-repeat;
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    color: rgba(0, 0, 0, 0.75);
    font-family: inherit;
    line-height: normal;
    border-radius: 0;
    padding: 0.5rem 25px 0.5rem 0.5rem;
    box-sizing: border-box;
}

select:hover {
    background-color: #f3f3f3;
    border-color: #999999;
}

select:disabled {
    background-color: #DDDDDD;
    cursor: default;
}

input[type="text"], input[type="password"], input[type="email"], input[type="number"], textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px;
    border-color: #cccccc;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.75);
    display: block;
    font-family: inherit;
    font-size: 0.875rem;
    height: 2.3125rem;
    margin: 0;
    padding: 0.5rem;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s linear, background 0.15s linear;
    -moz-transition: border-color 0.15s linear, background 0.15s linear;
    -ms-transition: border-color 0.15s linear, background 0.15s linear;
    -o-transition: border-color 0.15s linear, background 0.15s linear;
    transition: border-color 0.15s linear, background 0.15s linear;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="password"]:focus, textarea:focus {
    background: #fafafa;
    border-color: #999999;
    outline: none;
}

input[type="checkbox"] {
    line-height: 1rem;
}

label {
    text-align: left;
    display: block;
    font-size: 0.8rem;
    line-height: 1.5em;
    font-weight: normal;
    color: #4d4d4d;
}

label span.caption {
    display: block;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: normal;
    margin-top: -1px;
    padding: 0.33333rem 0.5rem 0.5rem;
    padding-top: 0.33333rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    background: #F9D15F;
    color: #000;
}

label span.very_weak {
    background-color: #ccffcc;
}

label span.weak {
    background-color: #66cc99;
}

label span.good {
    background-color: #66cc99;
}

label span.strong {
    background-color: #00B283;
    color: #fff;
}

label span.very_strong {
    background-color: #00B283;
    color: #fff;
}

label span.error {
    display: block;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 0.4em;
    margin-top: -1px;
    padding: 0.33333rem 0.5rem 0.5rem;
    padding-top: 0.33333rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    background: #f04124;
    color: #FFFFFF;
}

label.checkbox {
    padding: 0 0 0 0px;
    line-height: 1rem;
    font-size: 0.9rem;
}

button {
    cursor: pointer !important;
    z-index: 1;
}

.help_button{
    cursor: help;
}

.button {
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 1px solid #aaa;
    color: #333;
    font-size: 1rem;
    font-weight: normal;
    text-decoration: none;
    padding: 0.5em 0.5em;
    margin: 0;
    line-height: 2rem;
    text-align: center;
}

.button:hover{
    background-color: #f1eeee;
}

.button:active{
    transform: scale(0.98);
}

.primary_button {
    background-color: #960000;
    border-radius: 5px;
    border: 1px solid #960000;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    padding: 0.5em 0.5em;
    margin: 0;
    line-height: 2rem;
    text-align: center;
    cursor: pointer;
}
.primary_button:hover {
    background-color: #a70808;
}

.primary_button:active {
    transform: scale(0.98);
}


.help_button {
    float: right;
    border: 1px solid #aaa;
    border-radius: 5px;
    color: #333;
    background-color: #f0f0f0;
    text-decoration: none;
    padding: 0 0.5em;
    margin: 0;
    text-align: center;
}

.small_button {
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 1px solid #aaa;
    color: #333;
    text-decoration: none;
    padding: 0.5em 0.5em;
    margin: 0;
    text-align: center;
    font-size: 0.8rem;
    font-weight: normal;
    line-height: 1.6rem;
}

.small_button:hover{
    background-color: #f5d476;
}
.small_button:active{
    transform: scale(0.98);
}

#hide_password {
    margin-top: 0.8rem;
}

.col .primary_button, .col .small_button, .col .button {
    display: block;
    width: 100%;
    text-align: center;
}

.help_block {
    font-size: 0.85rem;
    margin: 0.5rem 0;
    padding: 0.5rem;
    background-color: #eee;
}

.warning_block {
    font-size: 0.85rem;
    margin: 0.5rem 0;
    padding: 0.5rem;
    background-color: #F9D15F;
    font-weight: bold;
    text-align: center;
}

.error_block {
    font-size: 0.85rem;
    font-style: italic;
    font-weight: normal;
    margin: 0.5rem 0 1rem 0;
    padding: 0.5rem;
    background: #f04124;
    color: #fff;
    text-align: center;
}

.notice {
    font-weight: bold;
}

.danger {
    background-color: #F9D15F;
}

.hint {
    text-align: center;
    font-size: 0.8em;
    /* IE 8 */
    font-size: 0.9rem;
    font-style: italic;
    margin: 0.3rem;
}

.right {
    float: right;
}

.col > a {
    text-decoration: none !important;
}

.help_block-p {
    margin: 0 !important;
}

#error_note {
    width: inherit;
    font-size: 0.85rem;
    font-style: italic;
    font-weight: normal;
    margin: 0.5rem 0 1rem 0;
    padding: 0.5rem;
    background: #f04124;
    color: #fff;
    text-align: center;
}